<template>
  <div style="min-height: 100vh">
    <div class="pa-4">
      <navbar-profile :items="items"></navbar-profile>
    </div>
    <div class="px-5 pb-3">
      <bullet
        :canBack="true"
        :colorIcon="'#052633'"
        :title="'Detail Invoice'"
      />
      <v-divider class="my-5"></v-divider>
      <div v-if="!loading && dataSet" class="d-flex align-center">
        <div class="d-flex align-center" style="flex-grow: 1">
          <h4>Invoice Psikotes {{ dataSet[idx].id }}</h4>
          <v-card
            flat
            class="py-1 px-4 ml-2 rounded-lg"
            :color="dataSet[idx].status == 1 ? '#106967' : '#DC3545'"
          >
            <p
              class="medium_txt pa-0 ma-0 text-capitalize"
              style="color: white; text-align: center"
            >
              {{ dataSet[idx].status == 1 ? "Done" : "Belum" }}
            </p>
          </v-card>
        </div>
        <div class="d-flex align-center">
          <v-btn
            :disabled="idx <= 0 ? true : false"
            @click="prevPage(idx - 1)"
            icon
            class="mr-2"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn
            :disabled="idx >= dataSet.length - 1 ? true : false"
            @click="prevPage(idx + 1)"
            icon
            class="ml-2"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </div>
      <div v-if="loading">
        <v-skeleton-loader type="list-item" width="300px"></v-skeleton-loader>
      </div>
      <div class="mt-3">
        <v-row no-gutters>
          <v-col cols="12" md="9" class="pa-8" style="background: #cfe1e1">
            <div class="pa-4 rounded" style="background: white">
              <h2 v-if="!loading && dataSet">{{ dataSet[idx].klien.nama }}</h2>
              <v-skeleton-loader
                v-if="loading"
                class="rounded-lg"
                width="100px"
                height="30px"
                type="image"
              ></v-skeleton-loader>
              <v-divider class="my-4"></v-divider>
              <div v-if="!loading && dataSet" class="d-flex align-center">
                <h4 style="flex-grow: 2">Invoice Psikotes</h4>
                <div
                  class="d-flex align-center justify-space-between"
                  style="flex-grow: 1"
                >
                  <h4>Total Tagihan</h4>
                  <h4>Rp. {{ formatRupiah(dataSet[idx].total_tagihan) }}</h4>
                </div>
              </div>
              <div v-if="loading">
                <v-skeleton-loader
                  type="list-item-three-line"
                ></v-skeleton-loader>
              </div>
              <div class="mt-4 d-flex align-center">
                <v-row no-gutters justify="space-between">
                  <v-col cols="12" md="6" class="pr-1">
                    <div v-if="!loading && dataSet" class="mt-4">
                      <h4 class="not_bold">Nama Pelanggan</h4>
                      <h4>
                        {{ dataSet[idx].customer.nama_lengkap }}
                      </h4>
                    </div>
                  </v-col>
                  <v-col cols="12" md="6" class="pl-1">
                    <div v-if="!loading && dataSet" class="mt-2">
                      <h4 class="not_bold">ID Transaksi</h4>
                      <h4>
                        {{ dataSet[idx].id }}
                      </h4>
                    </div>
                  </v-col>
                  <v-col cols="12" md="6" class="pr-1">
                    <div v-if="!loading && dataSet" class="mt-2">
                      <h4 class="not_bold">Tanggal Transaksi</h4>
                      <h4>
                        {{
                          $date(dataSet[idx].tgl_transaksi).format("DD/MM/YYYY")
                        }}
                      </h4>
                    </div>
                  </v-col>
                  <v-col cols="12" md="6" class="px-1">
                    <div v-if="!loading && dataSet" class="mt-2">
                      <h4 class="not_bold">Tanggal Jatuh Tempo</h4>
                      <h4>
                        {{ $date(dataSet[idx].tgl_jatuh).format("DD/MM/YYYY") }}
                      </h4>
                    </div>
                  </v-col>
                </v-row>
              </div>
              <div v-if="!loading && dataSet">
                <v-simple-table
                  class="mt-4 pb-2 rounded"
                  style="overflow: hidden; border: 1px solid grey"
                >
                  <template v-slot:default>
                    <thead style="background: #052633">
                      <tr>
                        <th class="text-left" style="color: white">Layanan</th>
                        <th class="text-left" style="color: white">
                          Kuantitas
                        </th>
                        <th class="text-left" style="color: white">
                          Harga Satuan
                        </th>
                        <th class="text-left" style="color: white">Diskon</th>
                        <th class="text-left" style="color: white">Pajak</th>
                        <th class="text-left" style="color: white">Jumlah</th>
                      </tr>
                    </thead>
                    <tbody style="background: white">
                      <tr v-for="(item, n) in itemsInvoice" :key="n">
                        <td>
                          <h4 class="text-capitalize">{{ item.layanan }}</h4>
                        </td>

                        <td>
                          <h4 class="text-capitalize">
                            {{ item.qty }}
                          </h4>
                        </td>
                        <td>
                          <h4 style="width: 100px" class="text-capitalize">
                            Rp. {{ formatRupiah(item.cost) }}
                          </h4>
                        </td>
                        <td>
                          <h4 class="text-capitalize">
                            Rp. {{ formatRupiah(item.diskon) }}
                          </h4>
                        </td>
                        <td>
                          <v-select
                            style="max-width: 120px"
                            class="my-2 rounded"
                            placeholder="Isi pajak"
                            hide-details
                            readonly
                            :items="optPajak"
                            item-text="text"
                            item-value="value"
                            :value="item.pajak"
                            dense
                            solo
                            flat
                            background-color="transparent"
                            append-icon=""
                          ></v-select>
                        </td>
                        <td>
                          <h4 class="text-capitalize">
                            Rp. {{ formatRupiah(item.jumlah) }}
                          </h4>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <!-- zz -->
                <div class="mt-4">
                  <v-row no-gutters>
                    <v-col cols="12" md="6">
                      <div v-if="!loading && dataSet" class="pa-2">
                        <h4 class="not_bold">Notes</h4>
                        <v-card outlined class="pa-2">
                          <p class="mb-0 smals_txt">{{ dataSet[idx].note }}</p>
                        </v-card>
                      </div>
                      <div v-if="loading" class="pa-2">
                        <v-skeleton-loader
                          class="rounded-lg"
                          width="90px"
                          height="20px"
                          type="image"
                        ></v-skeleton-loader>
                        <v-skeleton-loader
                          class="rounded-lg mt-1"
                          width="100%"
                          height="90px"
                          type="image"
                        ></v-skeleton-loader>
                      </div>
                    </v-col>
                    <v-col cols="12" md="6">
                      <div class="pa-2" v-if="!loading && dataSet">
                        <div class="d-flex justify-space-between">
                          <h4>Subtotal</h4>
                          <h4>Rp. {{ formatRupiah(field.total) }}</h4>
                        </div>
                        <div class="d-flex justify-space-between">
                          <h4>PPN</h4>
                          <h4>Rp. {{ formatRupiah(field.sum_pajak) }}</h4>
                        </div>
                        <v-divider class="my-4"></v-divider>
                        <div class="mt-2 d-flex justify-space-between">
                          <h4>Total Invoice</h4>
                          <h4>
                            Rp. {{ formatRupiah(dataSet[idx].total_tagihan) }}
                          </h4>
                        </div>
                      </div>
                      <div v-if="loading">
                        <v-skeleton-loader
                          type="list-item-three-line, article"
                        ></v-skeleton-loader>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <div v-if="loading">
                <v-skeleton-loader
                  class="pa-2 elevation-0"
                  type="table-tbody"
                ></v-skeleton-loader>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="3" class="py-8">
            <div>
              <h4 class="mx-4">Detail</h4>
              <v-divider class="my-3"></v-divider>
              <div v-if="!loading && dataSet" class="px-4">
                <h4 class="not_bold" style="color: #9ba0a3">Instansi</h4>
                <h4>{{ dataSet[idx].customer.instansi }}</h4>
              </div>

              <div v-if="!loading && dataSet" class="px-4 mt-2">
                <h4 class="not_bold" style="color: #9ba0a3">Jabatan</h4>
                <h4>{{ dataSet[idx].customer.jabatan }}</h4>
              </div>
              <div v-if="loading">
                <v-skeleton-loader type="article"></v-skeleton-loader>
              </div>
            </div>
            <div class="mt-6">
              <h4 class="mx-4">Tindakan</h4>
              <v-divider class="my-3"></v-divider>
              <div v-if="!loading && dataSet" class="px-4">
                <h5 class="not_bold" style="color: #9ba0a3">
                  Telah menerima pembayaran? Lakukanlah tindakan Penerimaan
                  Bayaran di bawah ini.
                </h5>
                <v-btn
                  dark
                  depressed
                  class="text-capitalize mt-3 bg_color1 px-3 py-2"
                  :to="`/admin/mku/invoice/detail/${dataSet[idx].id}/penerimaan`"
                >
                  Terima Pembayaran
                </v-btn>
              </div>
              <div v-if="loading">
                <v-skeleton-loader type="article"></v-skeleton-loader>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import bullet from "../../components/Etc/bullet.vue";
import NavbarProfile from "../../components/navbarProfile/navbarProfile.vue";
export default {
  components: { bullet, NavbarProfile },
  name: "detailInvoice",
  computed: {
    ...mapState({}),
  },
  data() {
    return {
      loadingUpdate: false,
      loading: true,
      dataSet: null,
      menuTransaksi: false,
      menuJatuh: false,
      persen_diskon: true,
      persen_potongan: false,
      idx: 0,
      itemsInvoice: [],
      optLayanan: [],
      field: {
        total: 0,
        total_diskon: 0,
        diskon_tamhahan_txt: 0,
        sum_pajak: 0,
        total_before_cut: 0,
        potongan_txt: 0,
      },
      optPajak: [
        { text: "PPN", value: 11 },
        { text: "Kosong", value: 0 },
      ],
      optCut: [{ text: "Seribu potongan", value: "seribu" }],
      items: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "Invoice",
          disabled: false,
          href: "/admin/mku/invoice",
        },
        {
          text: "Detail Invoice",
          disabled: true,
          href: "#",
        },
      ],
    };
  },
  mounted() {
    this.fetchDataLayanan();
    this.fetchData();
  },
  methods: {
    formatRupiah(angka) {
      var temp_angka = angka.toString().replace(".", ",");
      var number_string = temp_angka,
        split = number_string.split(","),
        sisa = split[0].length % 3,
        rupiah = split[0].substr(0, sisa),
        ribuan = split[0].substr(sisa).match(/\d{1,3}/gi);

      if (ribuan) {
        let separator = sisa ? "." : "";
        rupiah += separator + ribuan.join(".");
      }
      rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
      return rupiah;
    },
    fetchDataLayanan() {
      let data = {
        path: `sysadmin/event/daftar-layanan`,
      };
      this.$store
        .dispatch("client/getData", data)
        .then((data) => {
          this.optLayanan = data;
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.loading = false;
        });
    },
    fetchData() {
      this.loading = true;
      let data = {
        path: `sysadmin/admin/invoice/get`,
      };
      this.$store
        .dispatch("officer/getData", data)
        .then((data) => {
          this.itemsInvoice = [];
          console.log(data.data);
          this.dataSet = data.data;
          for (let i = 0; i < this.dataSet.length; i++) {
            if (this.dataSet[i].id == this.$route.params.idInvoice) {
              this.idx = i;
              break;
            }
          }

          if (this.dataSet[this.idx].item.length > 0) {
            this.dataSet[this.idx].item.forEach((item, index) => {
              this.itemsInvoice.push({
                id: item.id,
                layanan: item.name,
                layanan_id: item.layanan_id,
                jenis: item.jenis,
                qty: item.qty,
                cost: item.cost,
                diskon: item.diskon,
                pajak: item.pajak,
                jumlah: 0,
              });
              this.countSum(index);
            });
            this.countSum(-2);
          }
          this.loading = false;
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.loading = false;
        });
    },

    countSum(n) {
      let sumHarga = 0;
      let sumDiskon = 0;
      let sumPpn = 0;
      if (n != -2) {
        this.itemsInvoice[n].jumlah =
          this.itemsInvoice[n].qty * this.itemsInvoice[n].cost;
      }
      this.itemsInvoice.forEach((item) => {
        sumHarga =
          parseInt(item.qty) * parseInt(item.cost) + parseInt(sumHarga);
        sumDiskon =
          (parseInt(item.diskon) * (parseInt(item.qty) * parseInt(item.cost))) /
            100 +
          parseInt(sumDiskon);
        sumPpn = parseInt(item.pajak) + parseInt(sumPpn);
      });

      this.field.total = sumHarga;
      this.field.total_diskon = sumDiskon;
      if (this.dataSet[this.idx].persen_diskon) {
        this.field.diskon_tamhahan_txt =
          ((this.field.total - this.field.total_diskon) *
            this.dataSet[this.idx].diskon) /
          100;
      } else {
        this.field.diskon_tamhahan_txt = this.dataSet[this.idx].diskon;
      }
      this.field.sum_pajak =
        ((this.field.total -
          this.field.total_diskon -
          this.field.diskon_tamhahan_txt) *
          sumPpn) /
        100;
      100;
      this.field.total_before_cut =
        this.field.total -
        this.field.total_diskon -
        this.field.sum_pajak -
        this.field.diskon_tamhahan_txt;

      if (this.dataSet[this.idx].persen_potongan == 1) {
        this.field.potongan_txt =
          (this.field.total_before_cut * this.dataSet[this.idx].potongan) / 100;
      } else {
        this.field.potongan_txt = this.dataSet[this.idx].potongan;
      }
      if (this.dataSet[this.idx].is_cut) {
        this.dataSet[this.idx].total_tagihan =
          this.field.total_before_cut - this.field.potongan_txt;
      } else {
        this.dataSet[this.idx].total_tagihan = this.field.total_before_cut;
      }
    },
    sendEmail() {
      this.loadingUpdate = true;
      let item = {
        nama: this.dataSet.nama,
        tgl_transaksi: this.dataSet.tgl_transaksi,
        tgl_jatuh: this.dataSet.tgl_jatuh,
        id: this.dataSet.id,
        notes: this.dataSet.notes,
        total: this.dataSet.total,
        persen_diskon: this.dataSet.persen_diskon,
        persen_potongan: this.dataSet.persen_potongan,
        total_diskon: this.dataSet.total_diskon,
        diskon_tambahan: this.dataSet.diskon_tambahan,
        diskon_tamhahan_txt: this.dataSet.diskon_tamhahan_txt,
        sum_ppm: this.dataSet.sum_ppm,
        total_before_cut: this.dataSet.total_before_cut,
        isCut: this.dataSet.isCut,
        potongan: this.dataSet.potongan,
        potongan_txt: this.dataSet.potongan_txt,
        total_tagihan: this.dataSet.total_tagihan,
        itemsInvoice: this.itemsInvoice,
      };
      JSON.stringify(item);
      setTimeout(() => {
        this.loadingUpdate = false;
      }, 300);
    },
    prevPage(idx) {
      this.idx = idx;
      this.itemsInvoice = [];
      if (this.dataSet[this.idx].item.length > 0) {
        this.dataSet[this.idx].item.forEach((item, index) => {
          this.itemsInvoice.push({
            id: item.id,
            layanan: item.name,
            layanan_id: item.layanan_id,
            jenis: item.jenis,
            qty: item.qty,
            cost: item.cost,
            diskon: item.diskon,
            pajak: item.pajak,
            jumlah: 0,
          });
          this.countSum(index);
        });
        this.countSum(-2);
      }
    },
    nextPage(idx) {
      this.idx = idx;
      this.itemsInvoice = [];
      if (this.dataSet[this.idx].itemsInvoice.length > 0) {
        this.dataSet[this.idx].itemsInvoice.forEach((item) => {
          this.itemsInvoice.push(item);
        });
        this.countSum(-2);
      }
    },
  },
};
</script>

<style scoped>
.v-data-table tbody tr:nth-child(even) {
  background-color: white !important;
}
.parent {
  width: 60%;
}
@media (max-width: 960px) {
  .parent {
    width: 100%;
  }
}
</style>
